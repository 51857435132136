/* eslint-disable func-names */
import { v4 as uuidv4 } from 'uuid'
import {
  cloneDeep, isEmpty, sortBy, filter, isEqual,
  min,
  max,
  groupBy,
} from 'lodash-es'

import { SORT_ITINERARIES_TYPE } from '@/constants/flight'
import {
  apiBooking, apiPromotion, apiPromotionAirline, apiReservation,
} from '@/api/'
import store from '@/store'
import env from '@/libs/env'
import VueI18n from '@/libs/i18n'

const blankCustomServiceFee = {
  adultAmount: null,
  childAmount: null,
  infantAmount: null,
}
// SECTION STATE
const defaultState = () => ({
  // ANCHOR generals
  loading: false,
  progressValue: null,
  statusJobDone: false,

  // ANCHOR search
  searchFlight: {}, // data search
  distributorOptions: [], // search airline select

  // ANCHOR result
  resultSearchFlight: [], // ket qua tim kiem chuyen bay
  resultCombinationSearchFlight: [], // ket qua tim kiem chuyen bay gia ket hop
  selectedTrip: [],
  draftSelectedTrip: [], // phần chọn chuyến
  combinationSelectedTrip: null, // Chọn chuyến giá kết hợp
  tabIndexResult: 0,
  isDomestic: null,
  airlineRoundtrip: null, // chuyen bay round trip quoc te
  combinationKeys: null, // combination key for 1G
  expiredAtToReloadRequire: null,

  // ANCHOR Class booking
  resultSearchClassBooking: [], // result search class booking
  selectedTripClassBooking: [], // selected trip class booking
  statusClassBooking: [], // status class booking
  bookingClassCode: [], // bookingClassCode class booking
  selectedIdClassBooking: [], // selected class booking
  resBookingByClassBooking: null, // res created by class booking
  errCreateBookingText: null, // errCreate class booking
  dataCalcTripClassBooking: null, // data calc class booking
  statusCalcTripClassBooking: null, // status calc class booking
  airlineSearchClassBooking: '', // airline search class booking
  nextResultReference: null, // next Result Reference class booking
  addMoreFlightClassBooking: [],

  // ANCHOR Config filter class booking
  showTotalPriceADT: true, // show giá tổng hay giá net
  showTransitFlight: true, // show chuyến bay transit
  showPacificAirlines: true, // show chuyến bay có operating BL: Pacific Airlines
  filterDepartureTimeClassBooking: [], // default data: filter theo thời gian khởi hành(chặng đầu)
  filterByDepartureTimeClassBooking: [], // select data: filter theo thời gian khởi hành(chặng đầu)

  // ANCHOR Config view
  showPriceAfterDiscount: true,
  showPrice: 'TOTAL_FARE',
  showServiceFee: true,
  showAgencyFee: false,
  showBookingClass: true, // ẩn hiện hạng vé
  showAircraft: true, // ẩn hiện loại máy bay

  // ANCHOR Custom fee service
  customFeeServiceDataToModify: { ...blankCustomServiceFee },

  // ANCHOR profile fee service selected
  selectedProfileFeeService: null,

  // ANCHOR Filters
  filterSources: [], // source [vn, vu, vj, qh, 1g]
  filterAirlines: [], // airline [vn, qh, vj]
  filterStopPoints: [], // so diem dung [0,1,2]
  filterTransitPoints: [], // diem dung [DAD, BKK, SGN ...]
  filterAirCrafts: [], // loai/ten may bay
  filterDurations: [], // thoi gian bay [120min,140min...]
  filterPrices: [], // Gia ve Min _ Max [1200k, 6800k]
  filterDepartureTime: [], // thoi gian cat canh
  filterArrivalTime: [], // thoi gian ha canh
  filterSkyboss: false, // loai gia ve skyboss

  // choose
  filterBySources: [],
  filterByAirlines: [],
  filterByStopPoints: [],
  filterByTransitPoints: [],
  filterByDurations: [],
  filterByAirCrafts: [],
  filterByPrices: [],
  filterByDepartureTime: [],
  filterByArrivalTime: [],
  filterByFareTypeBusiness: false,
  filterByFareTypeSkyboss: false,

  // // ANCHOR airport
  // airports: [], // cac airport da tim kiem
  // airportGroup: {}, // airport group api

  // ANCHOR Create Booking
  paxSeatData: [],
  paxAddonsData: [],
  sortItinerariesType: SORT_ITINERARIES_TYPE.BY_CHEAPEST_FARE, // BY_CHEAPEST_FARE, // BY_EARLIEST_DEPARTURE // BY_SHORTEST_DURATION
  loadingFetchAncillary: false,
  classBookingCurrency: 'VND',

  // ANCHOR Create Group Booking
  isCreateGroupBooking: false,
  dataCreateGroupBooking: [],
  paxDataGroupBooking: null,
  isHideFunction: false,
})
// !SECTION

export default {
  namespaced: true,
  state: {
    // ANCHOR airport
    airports: [], // cac airport da tim kiem
    airportGroup: {}, // airport group api
    ...defaultState(),
  },
  // SECTION GETTERS
  getters: {
    // ANCHOR Generals
    getLoading: state => state.loading,
    getProgressValue: state => state.progressValue,
    getState: state => state,
    getStatusJobDone: state => state.statusJobDone,
    getDistributorOptions: state => state.distributorOptions,

    // ANCHOR Search
    getSearchFlight: state => state.searchFlight,
    getGroupedItineraryResponse: state => state.searchFlight?.isGroupedItineraryResponse || false,
    getActiveTabGroupedItineraryResponse: (state, getters) => {
      const isValidSource = ['1G', '1A'].some(it => state.searchFlight?.sources?.includes(it))
      return getters.getGroupedItineraryResponse && isValidSource
    },
    getSearchFlightArray: state => () => {
      let searchFlightArray = []
      const { searchFlight } = state
      if (
        (searchFlight.flights?.length > 1 && searchFlight.type === 'MC')
        || searchFlight.type === 'OW'
      ) {
        searchFlightArray = [...searchFlight.flights]
      }
      if (searchFlight.type === 'RT') {
        searchFlightArray.push({
          startPoint: searchFlight.flights[0].startPoint,
          endPoint: searchFlight.flights[0].endPoint,
          departDate: searchFlight.flights[0].departDate,
        })

        if (searchFlight.flights?.length === 1) {
          searchFlightArray.push({
            startPoint: searchFlight.flights[0].endPoint,
            endPoint: searchFlight.flights[0].startPoint,
            departDate: searchFlight.flights[0].returnDate,
          })
        } else {
          searchFlightArray.push({
            startPoint: searchFlight.flights[1].endPoint,
            endPoint: searchFlight.flights[1].startPoint,
            departDate: searchFlight.flights[1].departDate,
          })
        }
      }

      return searchFlightArray
    },
    getPromoCodes: state => state.searchFlight?.promoCodes || [],

    // ANCHOR result
    getResultSearchFlight: (state, getters) => {
      const sortByAirline = ['1G', 'VU', 'QH', 'VN', 'VJ']
      let result = []
      getters.getSearchFlightArray().forEach(() => result.push([]))
      state.resultSearchFlight.forEach(resultItem => {
        const { itineraryId, trips } = resultItem
        const index = itineraryId - 1
        if (!result[index]) result[index] = []
        result[index].push(...trips)
      })

      // function prioritySortBy(trip) {
      //   return ['MH'].includes(trip.airline) && ['SGN'].includes(trip.departure.IATACode) && ['KUL'].includes(trip.arrival.IATACode) ? 0 : 1
      // }
      if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_CHEAPEST_FARE) {
        result = result.map(trip => sortBy(trip, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return trip?.fareOptions[0]?.totalAdultModified
          }, trip => trip.departure.at, trip => trip.airline]))
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_EXPENSIVEST_FARE) {
        result = result.map(trip => sortBy(trip, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return -trip?.fareOptions[0]?.totalAdultModified
          }, trip => trip.departure.at, trip => trip.airline]))
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_AIRLINE) {
        result = result.map(trip => sortBy(trip, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return -sortByAirline.indexOf(trip.airline)
          }, trip => trip?.fareOptions[0]?.totalAdultModified, trip => trip.departure.at]))
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_EARLIEST_DEPARTURE) {
        result = result.map(trip => sortBy(trip, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return trip.departure.at
          }, trip => trip?.fareOptions[0]?.totalAdultModified, trip => trip.airline]))
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_LATEST_DEPARTURE) {
        result = result.map(trip => sortBy(trip, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return trip.departure.at
          }, trip => -trip?.fareOptions[0]?.totalAdultModified, trip => trip.airline]).reverse())
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_EARLIEST_ARRIVAL) {
        result = result.map(trip => sortBy(trip, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return trip.arrival.at
          }, trip => trip?.fareOptions[0]?.totalAdultModified, trip => trip.airline]))
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_LATEST_ARRIVAL) {
        result = result.map(trip => sortBy(trip, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return trip.arrival.at
          }, trip => -trip?.fareOptions[0]?.totalAdultModified, trip => trip.airline]).reverse())
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_SHORTEST_DURATION) {
        result = result.map(trip => sortBy(trip, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return trip.duration
          }, trip => trip?.fareOptions[0]?.totalAdultModified, trip => trip.arrival.at, trip => trip.airline]))
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_LONGEST_DURATION) {
        result = result.map(trip => sortBy(trip, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return -trip.duration
          }, trip => trip?.fareOptions[0]?.totalAdultModified, trip => trip.arrival.at, trip => trip.airline]))
      }
      return result
    },
    getResultCombinationSearchFlight: state => {
      let result = state.resultCombinationSearchFlight
      // function prioritySortBy(trip) {
      //   return ['MH'].includes(trip.airline) && ['SGN'].includes(trip?.journeys[0]?.departure.IATACode) && ['KUL'].includes(trip?.journeys[0]?.arrival.IATACode) ? 0 : 1
      // }
      if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_CHEAPEST_FARE) {
        result = sortBy(result, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return trip?.bestCombinationFare?.totalAdult
          }, trip => trip?.journeys[0]?.departure?.at, trip => trip?.airline])
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_EXPENSIVEST_FARE) {
        result = sortBy(result, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return -trip?.bestCombinationFare?.totalAdult
          }, trip => trip?.journeys[0]?.departure?.at, trip => trip?.airline])
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_AIRLINE) {
        result = sortBy(result, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return trip?.airline
          }, trip => trip?.bestCombinationFare?.totalAdult, trip => trip?.journeys[0]?.departure?.at])
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_EARLIEST_DEPARTURE) {
        result = sortBy(result, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return trip?.journeys[0]?.departure?.at
          }, trip => trip?.bestCombinationFare?.totalAdult, trip => trip?.airline])
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_LATEST_DEPARTURE) {
        result = sortBy(result, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return trip?.journeys[0]?.departure?.at
          }, trip => -trip?.bestCombinationFare?.totalAdult, trip => trip?.airline]).reverse()
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_EARLIEST_ARRIVAL) {
        result = sortBy(result, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return trip?.journeys[0]?.arrival?.at
          }, trip => trip?.bestCombinationFare?.totalAdult, trip => trip?.airline])
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_LATEST_ARRIVAL) {
        result = sortBy(result, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return trip?.journeys[0]?.arrival?.at
          }, trip => -trip?.bestCombinationFare?.totalAdult, trip => trip?.airline]).reverse()
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_SHORTEST_DURATION) {
        result = sortBy(result, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return trip?.journeys[0]?.duration
          }, trip => trip?.bestCombinationFare?.totalAdult, trip => trip?.journeys[0]?.arrival?.at, trip => trip?.airline])
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_LONGEST_DURATION) {
        result = sortBy(result, [
          // trip => prioritySortBy(trip),
          function (trip) {
            return -trip?.journeys[0]?.duration
          }, trip => trip?.bestCombinationFare?.totalAdult, trip => trip?.journeys[0]?.arrival?.at, trip => trip?.airline])
      }
      return result
    },
    getSelectedTrip: state => {
      const ITINERARY = {
        VJ: 1,
        VN1A: 1,
        '1A': 1,
        AK: 1,
      }

      const SEGMENT = {
        VN1A: 1,
        '1A': 1,
        QH: 1,
      }

      const resolveAirlineSegmentId = (source, segment) => SEGMENT[source]?.toString() || segment?.segmentId || null

      const result = state.selectedTrip.map((trip, index) => {
        if (!trip?.segments) return null

        const airlineItineraryId = ITINERARY[trip.source] ? ITINERARY[trip.source].toString() : null

        const data = {
          ...trip,
          // itineraryId: null,
          airlineItineraryId,
          segments: trip?.segments.map(segment => {
            const airlineSegmentId = ['VJ'].includes(trip.source)
              ? segment?.leg?.toString()
              : resolveAirlineSegmentId(trip.source, segment)

            const segmentData = {
              ...segment,
              tripId: index + 1,
              source: trip.source,
              groupClass:
                segment.airline === 'QH'
                  ? trip.fareOptions.groupClass
                  : segment.groupClass, // Đổi lại groupClass theo groupClass của giá vé đã chọn
              airlineItineraryId,
              airlineSegmentId,
            }

            if (SEGMENT[trip.source]) {
              SEGMENT[trip.source] += 1
            }

            return segmentData
          }),
        }

        if (ITINERARY[trip.source]) {
          ITINERARY[trip.source] += 1
        }

        return data
      })

      return result.filter(i => !!i)
    },
    getDraftSelectedTrip: state => state.draftSelectedTrip,
    getCombinationSelectedTrip: state => state.combinationSelectedTrip,
    getCombinationSelectedFareOptions: state => state.combinationSelectedTrip?.journeys.map(item => item.fareOptions) ?? null,
    getTabIndex: state => state.tabIndexResult,
    getIsDomestic: state => state.isDomestic,
    getAirlineRoundtrip: state => state.airlineRoundtrip,
    getCombinationKeys: state => state.combinationKeys,
    getExpiredAtToReloadRequire: state => state.expiredAtToReloadRequire,

    // ANCHOR - Class booking
    getResultSearchClassBooking: state => {
      let result = state.resultSearchClassBooking
      if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_CHEAPEST_FARE) {
        result = result.map(itinerary => ({
          ...itinerary,
          trips: sortBy(itinerary.trips, [function (trip) {
            return trip?.minFareADT
          }, trip => trip?.minDepartureDate]),
        }))
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_EARLIEST_DEPARTURE) {
        result = result.map(itinerary => ({
          ...itinerary,
          trips: sortBy(itinerary.trips, [function (trip) {
            return trip?.minDepartureDate
          }, trip => trip?.minFareADT]),
        }))
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_AIRLINE) {
        result = result.map(itinerary => ({
          ...itinerary,
          trips: sortBy(itinerary.trips, [function (trip) {
            return trip?.firstAirline
          }, trip => trip?.minDepartureDate]),
        }))
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_SHORTEST_DURATION) {
        result = result.map(itinerary => ({
          ...itinerary,
          trips: sortBy(itinerary.trips, [function (trip) {
            return trip?.minDuration
          }, trip => trip?.minDepartureDate]),
        }))
      } else if (state.sortItinerariesType === SORT_ITINERARIES_TYPE.BY_LONGEST_DURATION) {
        result = result.map(itinerary => ({
          ...itinerary,
          trips: sortBy(itinerary.trips, [function (trip) {
            return -trip?.minDuration
          }, trip => -trip?.minDepartureDate]),
        }))
      }

      const showTransitFlight = state.showTransitFlight
      if (!showTransitFlight) {
        result = result.map(itinerary => ({
          ...itinerary,
          trips: filter(itinerary.trips, trip => trip.segments.length < 2),
        }))
      }

      const showPacificAirlines = state.showPacificAirlines
      if (!showPacificAirlines) {
        result = result.map(itinerary => ({
          ...itinerary,
          trips: filter(itinerary.trips, trip => !trip.segments.find(s => ['BL'].includes(s.operating))),
        }))
      }

      const filterByAirCrafts = !isEmpty(state.filterByAirCrafts)
      if (filterByAirCrafts) {
        result = result.map(itinerary => ({
          ...itinerary,
          trips: filter(itinerary.trips, trip => trip.segments.some(s => state.filterByAirCrafts.some(it => it === s.airCraft))),
        }))
      }

      const filterByAirlines = !isEmpty(state.filterByAirlines)
      if (filterByAirlines) {
        result = result.map(itinerary => ({
          ...itinerary,
          trips: filter(itinerary.trips, trip => trip.segments.some(s => state.filterByAirlines.some(it => it === s.airline))),
        }))
      }

      const sortDeparture = state.filterByDepartureTimeClassBooking
      if (!isEmpty(sortDeparture)) {
        result = result.map(itinerary => ({
          ...itinerary,
          trips: filter(itinerary.trips, trip => trip.tripDeparture.hours() * 60 + trip.tripDeparture.minutes() >= sortDeparture[0]
            && trip.tripDeparture.hours() * 60 + trip.tripDeparture.minutes() <= sortDeparture[1]),
        }))
      }

      return result
    }, // class booking Result Search
    getSelectedTripClassBooking: state => state.selectedTripClassBooking, // class booking reservation
    getStatusClassBooking: state => state.statusClassBooking,
    getBookingClassCode: state => state.bookingClassCode,
    getSelectedIdClassBooking: state => state.selectedIdClassBooking,
    getDataCreatedBookingByClassBooking: state => state.resBookingByClassBooking,
    getErrCreateBookingText: state => state.errCreateBookingText,
    getDataCalcTripClassBooking: state => state.dataCalcTripClassBooking, // kết quả tính giá
    getStatusCalcTripClassBooking: state => state.statusCalcTripClassBooking, // trạng thái tính giá
    getAirlineSearchClassBooking: state => state.airlineSearchClassBooking, // airline search class booking
    getNextResultReference: state => state.nextResultReference, // next Result Reference class booking
    getAddMoreFlightClassBooking: state => state.addMoreFlightClassBooking,
    getClassBookingCurrency: state => state.classBookingCurrency,

    // ANCHOR Config filter class booking
    getShowTotalPriceADT: state => state.showTotalPriceADT,
    getShowTransitFlight: state => state.showTransitFlight,
    getShowPacificAirlines: state => state.showPacificAirlines,
    getFilterDepartureTimeClassBooking: state => state.filterDepartureTimeClassBooking,
    getDisableFilterDepartureTimeClassBooking: state => {
      const filterDeparture = state.filterDepartureTimeClassBooking
      if (isEmpty(filterDeparture)) return true
      const isEqualVal = isEqual(filterDeparture[0], filterDeparture[1])
      if (isEqualVal) return true
      return false
    },

    // ANCHOR Config show
    getSortItinerariesType: state => state.sortItinerariesType,
    getShowPrice: state => state.showPrice,
    getShowPriceAfterDiscount: state => state.showPriceAfterDiscount,
    getShowServiceFee: state => state.showServiceFee,
    getShowAgencyFee: state => state.showAgencyFee && state.showPrice === 'TOTAL_FARE',
    getShowBookingClass: state => state.showBookingClass,
    getShowAircraft: state => state.showAircraft,

    // ANCHOR filters
    getFilterSources: state => state.filterSources,
    getFilterAirlines: state => state.filterAirlines,
    getFilterStopPoints: state => state.filterStopPoints,
    getFilterTransitPoints: state => state.filterTransitPoints,
    getFilterDurations: state => state.filterDurations,
    getFilterAirCrafts: state => state.filterAirCrafts,
    getFilterPrices: state => state.filterPrices,
    getFilterDepartureTime: state => state.filterDepartureTime,
    getFilterArrivalTime: state => state.filterArrivalTime,
    getFilterSkyboss: state => state.filterSkyboss,

    getFilterBySources: state => state.filterBySources,
    getFilterByAirlines: state => state.filterByAirlines,
    getFilterByStopPoints: state => state.filterByStopPoints,
    getFilterByTransitPoints: state => state.filterByTransitPoints,
    getFilterByDurations: state => state.filterByDurations,
    getFilterByAirCrafts: state => state.filterByAirCrafts,
    getFilterByPrices: state => state.filterByPrices,
    getFilterByDepartureTime: state => state.filterByDepartureTime,
    getFilterByArrivalTime: state => state.filterByArrivalTime,
    getFilterByFareTypeBusiness: state => state.filterByFareTypeBusiness,
    getFilterByFareTypeSkyboss: state => state.filterByFareTypeSkyboss,

    // ANCHOR Airport
    getAirportByAirportCode: state => airportCode => {
      let airport = state.airports?.find(airport => airport.iata === airportCode)
      if (airport) {
        airport.name = `${airport.city}${airport.countryName !== 'Việt Nam' && airport.countryName !== airport.city ? `, ${airport.countryName}` : ''}`
        return airport
      }
      airport = state.airportGroup?.airports?.find(airport => airport.iata === airportCode)
      if (airport) airport.name = airport?.name.replace(', Việt Nam', '')
      return airport
    },
    getAirportGroup: state => state.airportGroup,

    // ANCHOR Create_Booking_Data
    getFlatSegment: (state, getters) => {
      // NOTE Using in Action
      const selectedTrip = getters.getSelectedTrip
      const trips = !isEmpty(selectedTrip)
        ? getters.getSelectedTrip
        : !isEmpty(getters.getCombinationSelectedTrip)
          ? (getters.getCombinationSelectedTrip?.journeys || [])
          : []

      let airlineSegmentId = 1
      const result = []

      trips.forEach((trip, tripIndex) => {
        if (['TR'].includes(trip.source)) {
          const groupSegment = groupBy(trip.segments, segment => `${segment.segmentId.split('-')[0]}`)
          for (const segmentIdKey in groupSegment) {
            if (Object.prototype.hasOwnProperty.call(groupSegment, segmentIdKey)) {
              result.push({
                ...groupSegment[segmentIdKey][0],
                source: trip.source,
                segmentFlights: groupSegment[segmentIdKey]
                  .map(segment => `${segment.departure.IATACode}-${segment.arrival.IATACode}`)
                  .join(' | '),
                boardPoint: groupSegment[segmentIdKey][0].departure.IATACode,
                offPoint: groupSegment[segmentIdKey][groupSegment[segmentIdKey].length - 1].arrival.IATACode,
                segmentIds: groupSegment[segmentIdKey].map(seg => seg.segmentId),
                cabinClass: groupSegment[segmentIdKey][0].groupClass,
              })
            }
          }
        } else {
          const segments = trip.segments.map(segment => {
            const dataSegment = {
              ...segment,
              ...(['VN1A', 'VJ'].includes(trip.source) && { groupClass: trip.fareOptions.groupClass }),
              ...((['VN1A'].includes(trip.source) && !isEmpty(getters.getCombinationSelectedTrip)) && {
                source: trip.source,
                airlineItineraryId: String(tripIndex + 1),
                airlineSegmentId: String(airlineSegmentId),
              }),
              segmentFlights: `${segment.departure.IATACode}-${segment.arrival.IATACode}`,
              cabinClass: ['VN'].includes(segment.airline) ? segment.bookingClass : segment.groupClass,
            }
            airlineSegmentId += 1
            return dataSegment
          })
          result.push(...segments)
        }
      })
      return result
    },

    getIsHideFunction: state => state.isHideFunction,
    getPaxSeatData: state => state.paxSeatData,
    getAddonsData: state => state.paxAddonsData,
    getSelectedSeatsDetails: state => {
      const seatSelectedFilter = state.paxSeatData.filter(s => s.seat !== null)
      return seatSelectedFilter
    },
    getLoadingFetchAncillary: state => state.loadingFetchAncillary,

    // ANCHOR FeeService
    getCustomFeeServiceDataToModify: state => state.customFeeServiceDataToModify,
    getSelectedProfileFeeService: state => state.selectedProfileFeeService,
    getFeeServiceInSelectedProfile: state => (isDomestic, airline) => {
      const ticketType = isDomestic ? 'INLAND' : 'INTERNATIONAL'
      return state.selectedProfileFeeService?.serviceFeeConfigs?.find(
        item => item.ticketType === ticketType && item.airline === airline,
      )
    },

    // ANCHOR Group Booking
    getIsCreateGroupBooking: state => state.isCreateGroupBooking,
    getDataCreateGroupBooking: state => state.dataCreateGroupBooking,
    getPaxDataGroupBooking: state => state.paxDataGroupBooking,
  },
  // !SECTION

  // SECTION MUTATIONS
  mutations: {
    // ANCHOR general
    RESET_STORE(state, isRemoveFeeServiceData) {
      const feeServiceObject = isRemoveFeeServiceData && !env.isCacheCustomServiceFee
        ? {}
        : {
          customFeeServiceDataToModify: state.customFeeServiceDataToModify,
          selectedProfileFeeService: state.selectedProfileFeeService,
        }

      if (env.isCacheCustomServiceFee) {
        if (isRemoveFeeServiceData) {
          sessionStorage.removeItem('feeServiceData')
        }
        const getFeeServiceDataFromSessionStorage = JSON.parse(sessionStorage.getItem('feeServiceData'))
        const customFeeServiceDataToModify = getFeeServiceDataFromSessionStorage?.custom
          ? { ...getFeeServiceDataFromSessionStorage?.custom }
          : undefined
        if (customFeeServiceDataToModify) {
          feeServiceObject.customFeeServiceDataToModify = customFeeServiceDataToModify
        }
        if (getFeeServiceDataFromSessionStorage?.profile) {
          feeServiceObject.selectedProfileFeeService = getFeeServiceDataFromSessionStorage?.profile
        } }
      Object.assign(state, { ...defaultState() }, { ...feeServiceObject })
    },
    SET_LOADING(state, val) {
      state.loading = val
    },
    SET_PROGRESS_VALUE(state, val) {
      state.progressValue = val
    },
    SET_STATUS_JOB_DONE(state, val) {
      state.statusJobDone = val
      state.progressValue = null
    },

    SET_DISTRIBUTOR_OPTIONS(state, val) {
      state.distributorOptions = val
    },

    // ANCHOR search
    UPDATE_SEARCH_FLIGHT(state, searchFlightData) {
      state.searchFlight = searchFlightData
    },

    // ANCHOR result
    PUSH_RESULT_SEARCH_FLIGHT(state, resultItem) {
      // const { itineraryId } = resultItem
      // const index = itineraryId - 1
      // if (!state.resultSearchFlight[index]) state.resultSearchFlight[index] = []
      // state.resultSearchFlight[index].push(...resultItem.trips)
      // state.draftSelectedTrip.push(null)
      state.resultSearchFlight.push(resultItem)
    },
    PUSH_RESULT_COMBINATION_SEARCH_FLIGHT(state, resultItem) {
      state.resultCombinationSearchFlight.push(...resultItem)
    },

    CLEAR_RESULT_SEARCH_FLIGHT(state) {
      state.draftSelectedTrip = []
      state.resultSearchFlight = []
    },
    SET_EXPIRED_AT_TO_RELOAD_REQUIRE(state, val) {
      state.expiredAtToReloadRequire = val
    },

    // ANCHOR - Class booking
    SET_DATA_CREATED_BOOKING_CLASS_BOOKING(state, val) {
      state.resBookingByClassBooking = val
    },

    SET_AIRLINE_SEARCH_CLASS_BOOKING(state, val) {
      state.airlineSearchClassBooking = val
    },

    SET_ERR_CREATE_BOOKING_TEXT(state, val) {
      state.errCreateBookingText = val
    },

    SET_DATA_CALC_TRIP_CLASS_BOOKING(state, val) {
      state.dataCalcTripClassBooking = val
    },

    SET_STATUS_CALC_TRIP_CLASS_BOOKING(state, val) {
      state.statusCalcTripClassBooking = val
    },

    SET_NEXT_RESULT_REFERENCE(state, val) {
      state.nextResultReference = val
    },

    SET_RESULT_SEARCH_CLASS_BOOKING(state, val) {
      state.resultSearchClassBooking = val
    },

    CLEAR_RESULT_SEARCH_CLASS_BOOKING(state) {
      state.resultSearchClassBooking = []
    },

    SET_STATUS_CLASS_BOOKING(state, val) {
      state.statusClassBooking = val
    },

    SET_BOOKING_CLASS_CODE(state, val) {
      state.bookingClassCode = val
    },

    SET_SELECTED_ID_CLASS_BOOKING(state, val) {
      state.selectedIdClassBooking = val
    },

    SET_SELECTED_TRIP_CLASS_BOOKING(state, val) {
      state.selectedTripClassBooking = val
    },

    SET_ADD_MORE_FLIGHT_CLASS_BOOKING(state, val) {
      state.addMoreFlightClassBooking = val
    },

    SET_CLASS_BOOKING_CURRENCY(state, val) {
      state.classBookingCurrency = val
    },

    SET_SELECTED_TRIP(state, val) {
      state.selectedTrip = val
    },
    SET_DRAFT_SELECTED_TRIP(state, { data, index }) {
      state.draftSelectedTrip[index] = data
    },
    SET_DRAFT_SELECTED_TRIP_ARRAY(state, val) {
      state.draftSelectedTrip = val
    },
    SET_COMBINATION_SELECTED_TRIP(state, val) {
      state.combinationSelectedTrip = val
    },
    SET_SORT_ITINERARIES_TYPE(state, val) {
      state.sortItinerariesType = val
    },

    SET_TAB_INDEX(state, val) {
      state.tabIndexResult = val
    },
    SET_IS_DOMESTIC(state, val) {
      state.isDomestic = val
    },
    SET_AIRLINE_ROUNDTRIP(state, val) {
      state.airlineRoundtrip = val
    },
    SET_COMBINATION_KEYS(state, val) {
      state.combinationKeys = val
    },

    // ANCHOR Config filter class booking
    SET_SHOW_TOTAL_PRICE_ADT(state, val) {
      state.showTotalPriceADT = val
    },
    SET_SHOW_TRANSIT_FLIGHT(state, val) {
      state.showTransitFlight = val
    },
    SET_SHOW_PACIFIC_AIRLINES(state, val) {
      state.showPacificAirlines = val
    },
    SET_FILTER_DEPARTURE_TIME_CLASS_BOOKING(state, val) {
      state.filterDepartureTimeClassBooking = val
    },
    SET_FILTER_BY_DEPARTURE_TIME_CLASS_BOOKING(state, val) {
      state.filterByDepartureTimeClassBooking = val
    },

    // ANCHOR Config show
    SET_SHOW_PRICE_NO_DISCOUNT(state, val) {
      state.showPriceAfterDiscount = val
    },
    SET_SHOW_PRICE(state, val) {
      state.showPrice = val
      if (val === 'NET_FARE') {
        state.showServiceFee = false
        state.showPriceAfterDiscount = false
      } else if (val === 'TICKET_FARE') {
        state.showPriceAfterDiscount = false
      } else if (val === 'TOTAL_FARE') {
        state.showServiceFee = true
        state.showPriceAfterDiscount = true
      }
    },
    SET_SHOW_SERVICE_FEE(state, val) {
      state.showServiceFee = val
    },
    SET_SHOW_AGENCY_FEE(state, val) {
      state.showAgencyFee = val
    },

    SET_SHOW_BOOKING_CLASS(state, val) {
      state.showBookingClass = val
    },

    SET_SHOW_AIRCRAFT(state, val) {
      state.showAircraft = val
    },

    // ANCHOR Filters
    SET_FILTER_SOURCES(state, val) {
      state.filterSources = val
    },
    SET_FILTER_AIRLINES(state, val) {
      state.filterAirlines = val
    },
    SET_FILTER_STOP_POINTS(state, val) {
      state.filterStopPoints = val
    },
    SET_FILTER_TRANSIT_POINTS(state, val) {
      state.filterTransitPoints = val
    },
    SET_FILTER_DURATIONS(state, val) {
      state.filterDurations = val
    },
    SET_FILTER_AIRCRAFT(state, val) {
      state.filterAirCrafts = val
    },
    SET_FILTER_PRICES(state, val) {
      state.filterPrices = val
    },
    SET_FILTER_DEPARTURE_TIME(state, val) {
      state.filterDepartureTime = val
    },
    SET_FILTER_ARRIVAL_TIME(state, val) {
      state.filterArrivalTime = val
    },
    SET_FILTER_SKYBOSS(state, val) {
      state.filterSkyboss = val
    },
    SET_FILTER_BY_SOURCES(state, val) {
      state.filterBySources = val
    },
    SET_FILTER_BY_AIRLINES(state, val) {
      state.filterByAirlines = val
    },
    SET_FILTER_BY_STOP_POINTS(state, val) {
      state.filterByStopPoints = val
    },
    SET_FILTER_BY_TRANSIT_POINTS(state, val) {
      state.filterByTransitPoints = val
    },
    SET_FILTER_BY_DURATIONS(state, val) {
      state.filterByDurations = val
    },
    SET_FILTER_BY_AIR_CRAFTS(state, val) {
      state.filterByAirCrafts = val
    },
    SET_FILTER_BY_PRICES(state, val) {
      state.filterByPrices = val
    },
    SET_FILTER_BY_DEPARTURE_TIME(state, val) {
      state.filterByDepartureTime = val
    },
    SET_FILTER_BY_ARRIVAL_TIME(state, val) {
      state.filterByArrivalTime = val
    },
    SET_FILTER_BY_FARE_TYPE_BUSINESS(state, val) {
      state.filterByFareTypeBusiness = val
    },
    SET_FILTER_BY_FARE_TYPE_SKYBOSS(state, val) {
      state.filterByFareTypeSkyboss = val
    },

    // ANCHOR airport
    PUSH_AIRPORTS(state, airportList) {
      airportList.forEach(e => {
        if (!state.airports.find(ele => ele.id === e.id)) state.airports.push(e)
      })
    },
    SET_AIRPORT_GROUP(state, val) {
      state.airportGroup = val
    },

    // ANCHOR Create_Booking_Data
    SET_DEFAULT_DATA_PAX_SEAT(state, val) {
      state.paxSeatData = val
    },

    SET_IS_HIDE_FUNCTION(state) {
      state.isHideFunction = !state.isHideFunction
    },

    SET_SEAT_SELECT(state, val) {
      state.paxSeatData[val.index].seat = val.seat
    },
    SET_ADDONS_SELECT_DATA(state, val) {
      state.paxAddonsData = val
    },
    RESET_DATA_TRIPS(state) {
      state.paxSeatData = []
    },

    ADD_NEW_PASSENGER(state, val) {
      state.searchFlight[val] += 1
    },

    DELETE_PASSENGER(state, val) {
      state.searchFlight[val] -= 1
    },

    SET_LOADING_FETCH_ANCILLARY(state, val) {
      state.loadingFetchAncillary = val
    },

    // ANCHOR Custom_Fee_Service
    SET_CUSTOM_FEE_SERVICE_DATA_TO_MODIFY(state, val) {
      state.customFeeServiceDataToModify = val
      state.selectedProfileFeeService = null
      if (env.isCacheCustomServiceFee) {
        sessionStorage.setItem('feeServiceData', JSON.stringify({ custom: val, profile: null }))
      }
    },
    SET_SELECTED_PROFILE_FEE_SERVICE(state, val) {
      state.selectedProfileFeeService = val
      state.customFeeServiceDataToModify = {
        adultAmount: null,
        childAmount: null,
        infantAmount: null,
      }
      if (env.isCacheCustomServiceFee) {
        sessionStorage.setItem('feeServiceData', JSON.stringify({ custom: null, profile: val }))
      }
    },

    // ANCHOR Group_Booking

    SET_IS_CREATE_GROUP_BOOKING(state, val) {
      state.isCreateGroupBooking = val
    },
    SET_DATA_CREATE_GROUP_BOOKING(state, val) {
      state.dataCreateGroupBooking = val
    },
    SET_PAX_DATA_GROUP_BOOKING(state, val) {
      state.paxDataGroupBooking = val
    },
  },
  // !SECTION

  // SECTION ACTIONS
  actions: {
    // ANCHOR General
    setLoading({ commit }, val) {
      commit('SET_LOADING', val)
    },
    setProgressValue({ commit }, val) {
      commit('SET_PROGRESS_VALUE', val)
    },
    setStatusJobDone({ commit }, val) {
      commit('SET_STATUS_JOB_DONE', val)
    },
    resetStore({ commit }, isRemoveFeeServiceData) {
      commit('RESET_STORE', isRemoveFeeServiceData)
    },

    // ANCHOR search
    setSearchFlight({ commit }, payload) {
      commit('UPDATE_SEARCH_FLIGHT', payload)
    },
    searchFlight({ commit, dispatch }, payload) {
      dispatch('setSearchFlight', payload)
      commit('CLEAR_RESULT_SEARCH_FLIGHT')
      return apiBooking.searchFlightv2({
        ...payload,
        // xoá airlines thừa
        airlines: undefined,
      })
    },
    getFlightSearchOperation(_ctx, params) {
      // v2
      return apiBooking.getFlightSearchOperation(params)
    },
    getResourceItinerary(_ctx, params) {
      // v2
      return apiBooking.getResourceItinerary(params)
    },

    // ANCHOR - Class booking
    searchClassBooking({ commit }, payload) {
      // commit('UPDATE_SEARCH_FLIGHT', payload) // UPDATE_SEARCH_FLIGHT on handle, case refresh flight

      commit('CLEAR_RESULT_SEARCH_CLASS_BOOKING')
      return apiBooking.searchClassBooking(payload)
    },

    searchMoreResultClassBooking(_ctx, payload) {
      return apiBooking.searchClassBooking(payload)
    },

    getClassBookingInfoTrip(ctx, payload) {
      return apiBooking.getClassBookingInfoTrip(payload)
    },

    setDataCreatedBookingClassBooking({ commit }, data) {
      commit('SET_DATA_CREATED_BOOKING_CLASS_BOOKING', data)
      commit('SET_ERR_CREATE_BOOKING_TEXT', null)
    },

    setStatusCalcTripClassBooking({ commit }, data) {
      commit('SET_STATUS_CALC_TRIP_CLASS_BOOKING', data)
    },

    setDataCalcTripClassBooking({ commit }, data) {
      commit('SET_DATA_CALC_TRIP_CLASS_BOOKING', data)

      // status calc
      commit('SET_STATUS_CALC_TRIP_CLASS_BOOKING', true)

      // NOTE: thêm tính giá done => true,
      // nếu selected trip thay đổi thì giá mới tính là giá cũ => false,
      // default null là chưa tính giá, research => default, status => null
      // change selected trip => false, status => false
    },

    setErrCreateBookingText({ commit }, data) {
      commit('SET_ERR_CREATE_BOOKING_TEXT', data)
      commit('SET_DATA_CREATED_BOOKING_CLASS_BOOKING', null)
    },

    async setDistributorOptions({ commit }) {
      // lấy từ globalConfig đã fetch sẵn để set vào booking store
      const data = await store.getters['globalConfig/getAllAvailableDistributors']
      if (data.length) {
        commit('SET_DISTRIBUTOR_OPTIONS', data.concat(['NDC', 'GDS']) || [])
      }
    },

    setAirlineSearchClassBooking({ commit }, data) {
      commit('SET_AIRLINE_SEARCH_CLASS_BOOKING', data)
    },

    setNextResultReference({ commit }, data) {
      commit('SET_NEXT_RESULT_REFERENCE', data)
    },

    updateResultDataSearchClassBooking({ commit }, data) {
      // result data
      commit('SET_RESULT_SEARCH_CLASS_BOOKING', data)
    },

    setDefaultDataAndResultSearchClassBooking({ commit }, data) {
      // result data
      commit('SET_RESULT_SEARCH_CLASS_BOOKING', data)
      // Default status
      const status = data.map(() => ['NN'])
      commit('SET_STATUS_CLASS_BOOKING', status)
      // Default selectedTrip list
      const selected = data.map(() => [])
      commit('SET_SELECTED_ID_CLASS_BOOKING', selected)
      // Default bookingClassCode list
      const bookingClassCode = data.map(() => [null])
      commit('SET_BOOKING_CLASS_CODE', bookingClassCode)
      // Default data re-calculate price
      const dataCalc = null
      commit('SET_DATA_CALC_TRIP_CLASS_BOOKING', dataCalc)
      // Default status data calc
      commit('SET_STATUS_CALC_TRIP_CLASS_BOOKING', dataCalc)
    },

    setSelectedTripClassBooking({ commit }, data) {
      commit('SET_SELECTED_TRIP_CLASS_BOOKING', cloneDeep(data))
    },

    setStatusClassBooking({ commit }, data) {
      commit('SET_STATUS_CLASS_BOOKING', cloneDeep(data))
    },

    setBookingClassCode({ commit }, data) {
      commit('SET_BOOKING_CLASS_CODE', cloneDeep(data))
    },

    setSelectedIdClassBooking({ commit }, data) {
      commit('SET_SELECTED_ID_CLASS_BOOKING', cloneDeep(data))
    },

    // ANCHOR result
    pushResultSearchFlight({ commit }, resultItem) {
      const modifyTrips = resultItem.trips.map(trip => {
        trip.fareOptions = sortBy(trip.fareOptions, [
          item => item.afterMultiple.total,
        ])
        trip.id = `${resultItem?.itineraryId}-${trip?.source}-${trip?.flightNumber}-${trip?.departure?.at}-${trip?.arrival?.at}-${uuidv4()}`
        return trip
      })
      commit('PUSH_RESULT_SEARCH_FLIGHT', {
        ...resultItem,
        trips: modifyTrips,
      })
    },
    pushResultCombinationSearchFlight({ commit }, resultItem) {
      commit('PUSH_RESULT_COMBINATION_SEARCH_FLIGHT', resultItem)
    },
    setTabIndex({ commit }, val) {
      commit('SET_TAB_INDEX', val)
    },
    setSelectedTrip({ commit }, val) {
      commit('SET_SELECTED_TRIP', val)
    },
    setDraftSelectedTrip({ commit }, val) {
      commit('SET_DRAFT_SELECTED_TRIP', val)
    },
    setDraftSelectedTripArray({ commit }, val) {
      commit('SET_DRAFT_SELECTED_TRIP_ARRAY', val)
    },
    setCombinationSelectedTrip({ commit }, val) {
      commit('SET_COMBINATION_SELECTED_TRIP', val)
    },
    selectCombinationSelectedJourney({ commit, state, dispatch }, { tripId, journey }) {
      if (tripId === state?.combinationSelectedTrip?.id) {
        const journeyIndex = journey.sequence - 1
        const newCombinationSelectedTrip = cloneDeep(state.combinationSelectedTrip)
        newCombinationSelectedTrip.journeys.splice(journeyIndex, 1, journey)
        commit('SET_COMBINATION_SELECTED_TRIP', newCombinationSelectedTrip)
      } else {
        dispatch('setCombinationSelectedTripByTripId', { tripId, journey })
      }
    },
    setCombinationSelectedTripByTripId({ dispatch, getters }, { tripId, journey }) {
      const tripFoundInResults = getters.getResultCombinationSearchFlight.find(trip => trip.id === tripId)
      const modifyTrip = cloneDeep(tripFoundInResults)
      delete modifyTrip.fareOptions
      modifyTrip.journeys = modifyTrip.journeys.filter(j => {
        if (j.sequence === journey.sequence) {
          return j.id === journey.id
        }
        return modifyTrip.bestCombinationFare.journeyIds.includes(j.journeyId)
      })
      dispatch('setCombinationSelectedTrip', modifyTrip)
    },
    setSortItinerariesType({ commit }, val) {
      commit('SET_SORT_ITINERARIES_TYPE', val)
    },
    fetchCheapestFare(_ctx, payload) {
      return apiBooking.fetchCheapestFare(payload)
    },
    setIsDomestic({ commit }, val) {
      commit('SET_IS_DOMESTIC', val)
    },
    setAirlineRoundtrip({ commit }, val) {
      commit('SET_AIRLINE_ROUNDTRIP', val)
    },
    setCombinationKeys({ commit }, val) {
      commit('SET_COMBINATION_KEYS', val)
    },

    fetchPromotionAvailable(_ctx, payload) {
      return apiPromotion.fetchPromotionsAvailable(payload)
    },
    fetchPromotionAirlineAvailable(_ctx, payload) {
      return apiPromotionAirline.fetchPromotionAirlineAvailable(payload)
    },

    retrieveVisa(_ctx, payload) {
      return apiBooking.retrieveVisa(payload)
    },

    getCountriesName(_ctx, payload) {
      return apiReservation.getCountriesName(payload)
    },

    setExpiredAtToReloadRequire({ commit }, val) {
      commit('SET_EXPIRED_AT_TO_RELOAD_REQUIRE', val)
    },

    // ANCHOR Filters
    setFilterSources({ commit }, val) {
      commit('SET_FILTER_SOURCES', val)
    },
    setFilterAirlines({ commit }, val) {
      commit('SET_FILTER_AIRLINES', val)
    },
    setFilterStopPoints({ commit }, val) {
      commit('SET_FILTER_STOP_POINTS', val)
    },
    setFilterTransitPoints({ commit }, val) {
      commit('SET_FILTER_TRANSIT_POINTS', val)
    },
    setFilterDurations({ commit }, val) {
      commit('SET_FILTER_DURATIONS', val)
    },
    setFilterAirCrafts({ commit }, val) {
      commit('SET_FILTER_AIRCRAFT', val)
    },
    setFilterPrices({ commit }, val) {
      commit('SET_FILTER_PRICES', val)
    },
    setFilterDepartureTime({ commit }, val) {
      commit('SET_FILTER_DEPARTURE_TIME', val)
    },
    setFilterArrivalTime({ commit }, val) {
      commit('SET_FILTER_ARRIVAL_TIME', val)
    },
    setFilterSkyboss({ commit }, val) {
      commit('SET_FILTER_SKYBOSS', val)
    },

    setFilterBySources({ commit }, val) {
      commit('SET_FILTER_BY_SOURCES', val)
    },
    setFilterByAirlines({ commit }, val) {
      commit('SET_FILTER_BY_AIRLINES', val)
    },
    setFilterByStopPoints({ commit }, val) {
      commit('SET_FILTER_BY_STOP_POINTS', val)
    },
    setFilterByTransitPoints({ commit }, val) {
      commit('SET_FILTER_BY_TRANSIT_POINTS', val)
    },
    setFilterByDurations({ commit }, val) {
      commit('SET_FILTER_BY_DURATIONS', val)
    },
    setFilterByAirCrafts({ commit }, val) {
      commit('SET_FILTER_BY_AIR_CRAFTS', val)
    },
    setFilterByPrices({ commit }, val) {
      commit('SET_FILTER_BY_PRICES', val)
    },
    setFilterByDepartureTime({ commit }, val) {
      commit('SET_FILTER_BY_DEPARTURE_TIME', val)
    },
    setFilterByArrivalTime({ commit }, val) {
      commit('SET_FILTER_BY_ARRIVAL_TIME', val)
    },
    setFilterByFareTypeBusiness({ commit }, val) {
      commit('SET_FILTER_BY_FARE_TYPE_BUSINESS', val)
    },
    setFilterByFareTypeSkyboss({ commit }, val) {
      commit('SET_FILTER_BY_FARE_TYPE_SKYBOSS', val)
    },
    sortEarliestFlights({ commit }, val) {
      commit('SORT_EARLIEST_FLIGHTS', val)
    },
    sortCheapestFlights({ commit }, val) {
      commit('SORT_CHEAPEST_FLIGHTS', val)
    },

    // ANCHOR reservation
    setAddonsSelectData({ commit }, val) {
      commit('SET_ADDONS_SELECT_DATA', val)
    },

    setDefaultDataPaxSeat({ getters, commit }, passengerList) {
      const segments = getters.getFlatSegment

      const result = segments.flatMap(segment => passengerList.map(passenger => ({
        segment,
        passenger,
        seat: null,
      })))

      commit('SET_DEFAULT_DATA_PAX_SEAT', result)
    },

    handleSeatSelect({ getters, commit }, selectedSeat) {
      const passengerSeatData = getters.getPaxSeatData

      const getData = (valueCheck, type = '') => {
        if (type === 'filter') {
          return passengerSeatData.filter(item => {
            if (['VN'].includes(selectedSeat.airline)) {
              return (item.segment.segmentFlights === selectedSeat.segmentFlights)
                  && (item.segment.airlineItineraryId === selectedSeat.itineraryId)
                  && (item.segment.source === selectedSeat.source)
            }
            return (item.segment.segmentFlights === selectedSeat.segmentFlights)
                && (item.segment.source === selectedSeat.source)
          })
        }

        return passengerSeatData.findIndex(item => {
          if (['VN'].includes(selectedSeat.airline)) {
            return item.segment.segmentFlights === valueCheck.segment.segmentFlights
                  && item.passenger.paxId === valueCheck.passenger.paxId
                  && item.segment.airlineItineraryId === valueCheck.segment.airlineItineraryId
          }
          return item.segment.segmentFlights === valueCheck.segment.segmentFlights
                && item.passenger.paxId === valueCheck.passenger.paxId
        })
      }

      let data = null
      const passengersInSameSegment = getData(null, 'filter')

      switch (true) {
        case passengersInSameSegment.every(p => p.seat !== null): {
          const existingSeat = passengersInSameSegment.find(
            p => p.seat && p.seat.seat.seatName === selectedSeat.seat.seatName,
          )
          if (existingSeat) {
            const passengerIndex = getData(existingSeat)
            data = {
              index: passengerIndex,
              seat: null,
            }
          }
          break
        }
        case passengersInSameSegment.every(p => p.seat === null): {
          const passengerIndex = getData(passengersInSameSegment[0])
          data = {
            index: passengerIndex,
            seat: selectedSeat,
          }
          break
        }
        default: {
          const existingSeat = passengersInSameSegment.find(
            p => p.seat && p.seat.seat.seatName === selectedSeat.seat.seatName,
          )
          if (existingSeat) {
            const passengerIndex = getData(existingSeat)
            data = {
              index: passengerIndex,
              seat: null,
            }
          } else {
            const passengerWithoutSeat = passengersInSameSegment.find(p => p.seat === null)
            const passengerIndex = getData(passengerWithoutSeat)
            data = {
              index: passengerIndex,
              seat: selectedSeat,
            }
          }
          break
        }
      }

      if (data) {
        commit('SET_SEAT_SELECT', data)
      }
    },

    resetDataTrips({ commit }) {
      commit('RESET_DATA_TRIPS')
    },

    fetchListAncillary({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_FETCH_ANCILLARY', true)
        apiBooking.fetchListAncillary(params)
          .then(res => resolve(res))
          .catch(error => reject(error))
          .finally(() => {
            commit('SET_LOADING_FETCH_ANCILLARY', false)
          })
      })
    },

    fetchSeatMap(ctx, params) {
      return apiBooking.fetchSeatMap(params)
    },

    addPassenger({ commit }, paxType) {
      commit('ADD_NEW_PASSENGER', paxType.toLowerCase())
    },

    deletePassenger({ commit }, paxType) {
      commit('DELETE_PASSENGER', paxType.toLowerCase())
    },

    // ANCHOR airport
    fetchAirports({ commit }, params) {
      return new Promise((resolve, reject) => {
        apiBooking
          .fetchAirports(params)
          .then(response => {
            commit('PUSH_AIRPORTS', response.data.items)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchAirportsByCodes({ commit }, params) {
      return apiBooking
        .fetchAirportsByCodes({
          airportCodes: params.length ? params.join(',') : null,
        })
        .then(response => {
          commit('PUSH_AIRPORTS', response.data)
          return response
        })
    },
    fetchAirportGroup({ commit }) {
      const params = {
        language: VueI18n.locale,
      }
      return new Promise((resolve, reject) => {
        apiBooking
          .fetchAirportGroup(params)
          .then(response => {
            commit('SET_AIRPORT_GROUP', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    // ANCHOR create booking
    createBooking(ctx, params) {
      return apiBooking.createBooking(params)
    },

    commandCreateBooking(ctx, params) {
      return apiBooking.commandCreateBooking(params)
    },

    createGroupBooking(ctx, params) {
      return apiBooking.createGroupBooking(params)
    },

    payNowConfirmPrice(ctx, payload) {
      return apiBooking.confirmPrice(payload)
    },

    getFareRules(ctx, payload) {
      return apiBooking.getFareRules(payload)
    },

    calculatePrice(ctx, payload) {
      return new Promise((resolve, reject) => {
        apiBooking.calculatePrice(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ANCHOR Config filter class booking
    setShowTotalPriceADT({ commit }, val) {
      commit('SET_SHOW_TOTAL_PRICE_ADT', val)
    },
    setShowTransitFlight({ commit }, val) {
      commit('SET_SHOW_TRANSIT_FLIGHT', val)
    },
    setShowPacificAirlines({ commit }, val) {
      commit('SET_SHOW_PACIFIC_AIRLINES', val)
    },
    setFilterDepartureTimeClassBooking({ getters, commit, dispatch }, val) {
      let data
      const filterTimes = cloneDeep(getters.getFilterDepartureTimeClassBooking)
      if (!isEmpty(filterTimes)) {
        const arrTime = filterTimes.concat(val)
        data = [min(arrTime), max(arrTime)]
        dispatch('setFilterByDepartureTimeClassBooking', cloneDeep(data))
      } else {
        data = val
      }
      commit('SET_FILTER_DEPARTURE_TIME_CLASS_BOOKING', cloneDeep(data))
    },
    setFilterByDepartureTimeClassBooking({ commit }, val) {
      commit('SET_FILTER_BY_DEPARTURE_TIME_CLASS_BOOKING', val)
    },

    setAddMoreFlightClassBooking({ commit }, val) {
      commit('SET_ADD_MORE_FLIGHT_CLASS_BOOKING', val)
    },

    setClassBookingCurrency({ commit }, val) {
      commit('SET_CLASS_BOOKING_CURRENCY', val)
    },

    // ANCHOR Config show
    setShowPriceAfterDiscount({ commit }, val) {
      commit('SET_SHOW_PRICE_NO_DISCOUNT', val)
    },
    setShowPrice({ commit }, val) {
      commit('SET_SHOW_PRICE', val)
    },
    setShowServiceFee({ commit }, val) {
      commit('SET_SHOW_SERVICE_FEE', val)
    },
    setShowAgencyFee({ commit }, val) {
      commit('SET_SHOW_AGENCY_FEE', val)
    },
    setShowBookingClass({ commit }, val) {
      commit('SET_SHOW_BOOKING_CLASS', val)
    },
    setShowAircraft({ commit }, val) {
      commit('SET_SHOW_AIRCRAFT', val)
    },
    // ANCHOR custom fee service
    setCustomFeeServiceDataToModify({ commit }, val) {
      commit('SET_CUSTOM_FEE_SERVICE_DATA_TO_MODIFY', { ...val })
    },

    // ANCHOR setSelectedProfileFeeService
    setSelectedProfileFeeService({ commit }, val) {
      commit('SET_SELECTED_PROFILE_FEE_SERVICE', { ...val })
    },

    // ANCHOR group-booking
    setIsCreateGroupBooking({ commit }, val) {
      commit('SET_IS_CREATE_GROUP_BOOKING', val)
    },
    setDataCreateGroupBooking({ commit }, val) {
      commit('SET_DATA_CREATE_GROUP_BOOKING', val)
    },
    setPaxDataGroupBooking({ commit }, val) {
      commit('SET_PAX_DATA_GROUP_BOOKING', val)
    },
    changePaxDataGroupBooking({ getters, dispatch }, val) {
      const dataPax = cloneDeep(getters.getPaxDataGroupBooking)
      val.forEach(item => {
        if (['increase'].includes(item.action)) dataPax[`${item.key.toLowerCase()}Modified`] += item.target
        if (['decrease'].includes(item.action)) dataPax[`${item.key.toLowerCase()}Modified`] -= item.target
      })
      dispatch('setPaxDataGroupBooking', dataPax)
    },
    toggleHideFunction({ commit }) {
      commit('SET_IS_HIDE_FUNCTION')
    },
  },
}
